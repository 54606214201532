// Do this next because other things rely on jquery being assigned
// to the global 'window' object (window.$ and window.jQuery)
import '@/boot_jquery';

import autosize from 'autosize';
import 'bootstrap-sass';

import '@/back_to_top';
import '@/toggle_leftnav';

$(() => {
    // Set textareas to auto-resize
    autosize(document.querySelectorAll('textarea'));

    /*
     *  Add a 'sectionNavActive' class to <li> in a #section_navigation where the <a> tag matches
     *  the current page.
     */
    $('ul.nav.auto-active li a').each((_, element) => {
        if ((element as HTMLAnchorElement).href === window.location.href) {
            $(element).closest('li').addClass('active');
        }
    });
});
